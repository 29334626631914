<template>
<ChirpList
    :data_source="rows"
    :export_api="export_api"
    :target_page="targetPage"
    :createAction="false"
    :loadingColumns="loadingTable"
    :column_meta="columnMeta"
    :totalCount="totalCount"
    :storeKey="storeKey"
    :useOverride="useOverride"
    ignore-date-conversion
    @cancelOverride="handleCancelOverride"
/>


</template>

<script>
    import ChirpList from '@/components/general/list/ChirpList';
    import { get_prefs } from '@/util/genericUtilityFunctions';
    import { tryGetFilter } from '@/util/tryGetFilter';
    import dayjs from '@/util/dayjs';
    import { mapGetters } from 'vuex';
    import { emails } from '@/util/apiRequests';

    export default {
        name: 'EmailLog',
        components: { ChirpList },
        props: {
            overrideFilter: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                rows: [],
                targetPage: 'EmailsLogDetail',
                columnMeta: [

                    {field: "client", headerText: '', hide: 1},
                    {field: "company_id", headerText: '', hide: 1},
                    {field: "dayt_sent", headerText: 'Sent Date & Time'},
                    {field: "email_template_title", headerText: '', hide: 1},
                    {field: "first_name", headerText: '', hide: 1},
                    {field: "id", headerText: '', hide: 1},
                    {field: "last_name", headerText: '', hide: 1},
                    {field: "mail_bcc", headerText: '', hide:  1},
                    {field: "mail_cc", headerText: '', hide: 1},
                    {field: "mail_from", headerText: '', hide: 1},
                    {field: "mail_to", headerText: 'To'},
                    {field: "message_id", headerText: '', hide: 1},
                    {field: "office_id", headerText: '', hide: 1},
                    {field: "status", headerText: 'Status'},
                    {field: "subject", headerText: 'Subject'},
                    {field: "type", headerText: '', hide: 1},
                    {field: "user", headerText: 'User'},
                    {field: "user_first_name", headerText: '', hide: 1},
                    {field: "user_last_name", headerText: '', hide: 1},
                    {field: "user_id_create", headerText: '', hide: 1},

                ],
                loading: true,
                loadingTable: false,
                totalCount: 0,
                useOverride: false,
                export_api: '/users/list',
                commandColumns: [
                    // {
                    //     field: "Commands",
                    //     headerText: "Actions",
                    //     commands: [{
                    //         buttonOption: { content: "Add", cssClass: "e-flat custombutton" },
                    //
                    //     }]
                    // }
                ]
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'emailLog';
            },

        },
        methods: {
            async load_data(){
                try {
                    const body = {
                        criteria: this.criteria
                    }
                    const res = await this.$api.post(emails.getList(), body);
                    this.rows = res.data.rows;
                    this.rows = this.rows.map(row => {
                        row.dayt_sent = dayjs(row.dayt_sent).format('MM/D/YY hh:mm A')
                        if(!row.user){
                            row.user = 'System';
                        }
                        return row;
                    });
                    this.totalCount = res.data.total_count;
                    this.$nextTick(() => {
                        this.loading = false;
                    });
                }catch(err){
                    this.$cl('err', err);
                }

            },
            async init(){
                try {
                    if (this.overrideFilter) {
                        this.useOverride = true;
                        this.$store.commit('filters/applyOverride', {
                            stateKey: this.storeKey,
                            criteria: this.overrideFilter
                        });
                    }

                    this.criteriaProps = get_prefs(this.pageMeta.page);
                    await this.load_data();
                }catch(err){
                    this.$cl('err', err);
                }
            },
            handleCancelOverride() {
                this.useOverride = false;
            }

        },
        async created() {
            await this.init()
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                }
            }
        },



    };
</script>

<style scoped>

</style>